.dochazka_state_2 {background-color: rgb(252, 249, 240); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_3 {background-color: rgb(255, 137, 99); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_4 {background-color: rgb(27, 156, 5); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_5 {background-color: rgb(40, 242, 5); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_6 {background-color: rgb(0, 201, 198); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_7 {background-color: rgb(145, 17, 0); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_8 {background-color: rgb(47, 176, 25); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_9 {background-color: rgb(255, 176, 25); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_10 {background-color: rgb(152, 158, 3); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_11 {background-color: rgb(152, 158, 3); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_12 {background-color: rgb(152, 158, 3); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_13 {background-color: rgb(152, 158, 3); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_14 {background-color: rgb(152, 158, 3); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_15 {background-color: rgb(152, 158, 3); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_16 {background-color: rgb(152, 158, 3); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_17 {background-color: rgb(146, 72, 52); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_18 {background-color: rgb(232, 227, 135); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_19 {background-color: rgb(232, 174, 135); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_20 {background-color: rgb(253, 181, 255); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}
.dochazka_state_21 {background-color: rgb(247, 212, 250); font-weight: bold; padding-right:0px !important; padding-left: 0px !important;text-align: center !important;}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.ag-popup-editor, .ag-popup, div.ag-popup-child, .ag-menu {
  z-index: 1300 !important;
}
.ag-header-cell {padding-left:15px !important; padding-right: 0px !important;}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
